import * as React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { graphql } from 'gatsby'
import {callHook} from '../utils/utils';
import settings from '../settings.json';
import translate from '../utils/translator'

export const CouponsForm = ({slice}) => {
  const {title, text} = slice.primary
  
  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    console.log('data: ', data);
    const url = settings.forms_webhook;
    const r = await callHook({url, uid:"sifi-eats-coupons", params:data});
    console.log(r);
    if (r && r.success === true) {
      setError(false);
      setLoading(false);
    } else {
      setError(true);
    }
    setSend(true);
  }

  if (isLoading && !isSend) {
    return (
      <div className="py-4">
			  <div className="container py-5">
          <div className="text-center col-12 col-lg-8 mx-auto">
            <div className="loader mx-auto"></div>
          </div>
        </div>
      </div>
    );
  }

  if (isSend) {
    return (
      <div className="py-4">
			  <div className="container py-5">
          <div className="text-center col-12 col-lg-8 mx-auto">
            {!isError && (
            <>
              <h3>Danke!</h3>
              <p>Wir werden Sie in Kürze kontaktieren.</p>
            </>
            )}
            {isError && (
            <>
              <h3>An error has occurred</h3>
              <p>Please try again later, server is not available now.</p>
            </>
            )}
            <div className="col-auto">
              <button onClick={()=>setSend(false)} className={"btn btn-primary"}>{isError ? "Try again" : translate('btn_new_request')}</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
  <div className="py-4">
		<div className="container py-5">
      <div className="col-12 col-md-9 col-lg-7 mx-auto row align-items-center">
        <form id="coupons" className="text-center col" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="email"
            className={'form-control'}
            placeholder="Your email address"
            required
            {...register('email', {required: true})}
          />
          <div className="invalid-feedback" wfd-invisible="true">{'Invalid email address'}</div>
          <div className="text-center mt-3 fs-0875">Type in or paste your email address here and we will send your QR-code to you right away.</div>
          <div className={"d-flex justify-content-center mt-4"}>
            <label className="form-check-label" for="flexCheckChecked"><input className="form-check-input me-2" type="checkbox" value="" id="flexCheckChecked" required/><span className="fs-0875">I agree to the <a href="/page/impressum">Terms of Service</a>, <a href="/page/datenschutzerklarung">Privacy Policy</a> and Use of Cookies.</span></label>
          </div>
          <input type="submit" value="Abonnieren" className={"mt-4 btn btn-primary"}/>
        </form>
      </div>
		</div>
	</div>
  )
}

export const query = graphql`
  fragment PageDataBodyCouponsForm on PrismicPageDataBodyCouponsForm {
    primary {
      title
      text {
        richText
      }
    }
  }
`
