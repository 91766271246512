import * as React from 'react'
import { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import de_DE from 'antd/lib/date-picker/locale/de_DE';
import {callHook} from '../utils/utils';
import settings from '../settings.json';
import translate from '../utils/translator'

export default function ReserveForm(form) {
  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const rdate = useRef();
  const rtime = useRef();

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    data.pageUrl = form.pageUrl
    if (rdate.current) data.date = rdate.current;
    if (rtime.current) data.time = rtime.current;
    console.log('data: ', data);
    const url = settings.forms_webhook;

    setLoading(true);
    const r = await callHook({url, uid:form.formId, params:data});
    console.log(r);
    if (r && r.success === true) {
      setError(false);
      setLoading(false);
    } else {
      setError(true);
    }
    setSend(true);
  }

  const onDateChange = (date, dateString) => {
    console.log('onDateChange: ', dateString);
    rdate.current = dateString;
  }

  const onTimeChange = (time, timeString) => {
    console.log('onTimeChange: ', timeString);
    rtime.current = timeString;
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current <= moment().add(-1, 'days');
  };

  if (isLoading && !isSend) {
    return (
      <div className="text-center py-4">
        <div className="loader mx-auto"></div>
      </div>
    );
  }

  if (isSend) {
    return (
      <div className="text-center pt-3">
        {!isError && (
        <>
          <h3>Danke!</h3>
          <p>Wir werden Sie in Kürze kontaktieren.</p>
        </>
        )}
        {isError && (
        <>
          <h3>An error has occurred</h3>
          <p>Please try again later, server is not available now.</p>
        </>
        )}
        <div className="col-auto">
          <button onClick={()=>setSend(false)} className={"btn btn-primary"}>{isError ? "Try again" : translate('btn_new_request')}</button>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <form id={form.formId} className="text-center" onSubmit={handleSubmit(onSubmit)}>
        <div className={""}>
          <input
            placeholder="Ihr Name"
            className={'form-control'}
            {...register('name', {required: false})}
          />
        </div>
        <div className={"mt-3"}>
          <input
            type="email"
            placeholder="Ihre E-Mail-Adresse"
            className={"form-control"}
            {...register('email', {required: false})}
          />
        </div>
        <div className={"mt-3"}>
          <input
            type="tel"
            placeholder="Ihre Telefonnummer"
            className={"form-control"}
            required
            {...register('phone', {required: true})}
          />
        </div>
        <div className={"mt-3"}>
          <div className="row g-3">
            <div className="col-12 col-md-6">
              <DatePicker
                className={"form-control"}
                disabledDate={disabledDate}
                locale={de_DE}
                onChange={onDateChange}
                format="DD.MM.YYYY"
              />
            </div>
            <div className="col-12 col-md-6">
              <TimePicker
                className={"form-control"}
                format={"HH:mm"}
                minuteStep={15}
                locale={de_DE}
                onChange={onTimeChange}
              />
            </div>
          </div>
        </div>
        <div className={"mt-3"}>
          <input
            placeholder="Personenzahl"
            className={"form-control"}
            {...register('persons', {required: false})}
          />
        </div>
        <div className={"mt-3"}>
          <textarea
            placeholder="Geben Sie hier Ihre Nachricht ein"
            type="text"
            rows="5"
            className={"form-control"}
            {...register('text', {required: false})}
          />
        </div>
        <input type="submit" value="Nachricht senden" className={"mt-4 btn btn-primary"}/>
      </form>
		</div>
  )
}
